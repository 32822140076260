<template>
    <div
        class="w-full p-4 h-screen flex justify-center items-center bg-gray-100"
    >
        <div class="text-center">
            <img src="/logo.png" class="w-20 m-auto" />
            <h2>Gevonden!</h2>
            <p class="max-w-xl">
                Goed gedaan! De eerste clue is succesvol ontcijferd. Welkom op
                de website van de GGFM. Dit markeert de start van een spannende
                speurtocht door Dreumelen. Laten we beginnen met het vormen van
                teams. Klik hieronder op 'Maak je team' en voeg je team toe.
            </p>
            <PrimaryButton @click="createTeam">Maak je team</PrimaryButton>
        </div>
    </div>

    <ModalTemplate
        title="Team aanmaken"
        :loaded="true"
        @close="teamModalVisible = false"
        :visible="teamModalVisible"
    >
        <form
            class="p-4 max-w-screen min-w-[300px]"
            @submit.prevent="form.post('/teams/create')"
        >
            <div>Teamnaam</div>
            <span class="text-red-500 text-sm" v-if="form.errors.teamName"
                >Team moet een naam hebben</span
            >
            <TextInput
                :maxlen="10"
                class="mb-4 w-full"
                v-maska
                type="text"
                data-maska="Z"
                data-maska-tokens="Z:[A-z ]:multiple"
                v-model="form.teamName"
            ></TextInput>

            <div class="p-2 bg-gray-900 rounded mb-4">
                <div class="text-white">Personen</div>
                <span
                    class="text-red-500 text-sm block"
                    v-if="form.errors.teamMates"
                    >Voeg een teamlid toe</span
                >
                <div v-for="(mate, index) in form.teamMates" class="flex">
                    <span
                        class="text-red-500 text-sm block"
                        v-if="form.errors['teamMates.' + index]"
                        >Dit veld mag niet leeg zijn'</span
                    >
                    <input
                        v-maska
                        type="text"
                        data-maska="Z"
                        data-maska-tokens="Z:[A-z ]:multiple"
                        class="mb-4 w-full"
                        v-model="form.teamMates[index]"
                    />
                    <div
                        class="fa fa-close text-white relative top-3.5 ml-3"
                        @click="removePerson(index)"
                    ></div>
                </div>

                <PrimaryButton @click.prevent="addPerson"
                    >Naam Toevoegen</PrimaryButton
                >
            </div>

            <PrimaryButton type="submit">Team Aanmaken</PrimaryButton>
        </form>
    </ModalTemplate>
</template>

<script setup>
import ModalTemplate from "@/Modals/ModalTemplate.vue";
import { ref } from "vue";
import TextInput from "@/Components/TextInput.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import { useForm } from "@inertiajs/vue3";
import { vMaska } from "maska";

let teamModalVisible = ref(false);

defineProps({ errors: Object });

function createTeam() {
    teamModalVisible.value = true;
}

const form = useForm({
    teamName: "",
    teamMates: [],
});

function addPerson() {
    form.teamMates.push("");
}

function removePerson(index) {
    form.teamMates.splice(index, 1);
}
</script>

<style>
h2 {
    @apply text-3xl mb-4;
}

p {
    @apply mb-4;
}
</style>
